import React from 'react'
import { BrowserRouter, Outlet, Route, Routes, useRoutes } from 'react-router-dom'
import Login from '../screens/Login/Login.screen';
import LoginMR from '../screens/MR/Login/Login.screen';
import Registration from '../screens/Customer/Registration/Registration';
import PageNotFound from '../screens/PageNotFound/PageNotFound.screen';
import OrderPlaced from '../screens/Order/OrderPlaced/OrderPlaced';
import PlaceOrder from '../screens/Order/PlaceOrder/PlaceOrder';
import { loginGuard, customerLoginGuard, mrLoginGuard } from '../middleware/guard';
import OrderInformation from '../screens/Order/OrderInformation/OrderInformation';
import {
  useGuardedRoutes,
  GuardConfigProvider,
  GuardProvider
} from 'react-router-guarded-routes'
import FeedbackScreen from '../screens/Feedback/Feedback.screen';
import FeedbackFromOrderScreen from '../screens/Feedback/FeedbackFromOrder.screen';
import FeedbackSubmitScreen from '../screens/Feedback/FeedbackSubmit.screen';
import OrderHistory from '../screens/Order/OrderHistory/OrderHistory.screen';
import OrderHistoryTracking from '../screens/Order/OrderHistory/OrderHistoryTracking.screen';
import MrDetailsScreen from '../screens/MR/List/MrDetails.screen';
import PrivacyPolicy from '../screens/PrivacyPolicy/PrivacyPolicy.screen';
import OrderInfo from '../screens/Order/OrderInformation/OrderInfo';
import OrderInfoTracking from '../screens/Order/OrderInformation/OrderInfoTracking';
import FeedbackListScreen from '../screens/Feedback/FeedbackList.screen';
import LoginBaseScreen from '../screens/Login/LoginBase.screen';
import LoginCustomerTrackingScreen from '../screens/Login/LoginCustomerTracking.screen';
import CallCenterListScreen from '../screens/CallCenter/List/CallCenterList.screen'
import TrackInfoOrder from '../screens/Order/TrackOrder/TrackInfoOrder';
import TrackOrderByOrderId from '../screens/Order/TrackOrder/TrackOrderByOrderId';
import RegistrationScreenCallCenter from '../screens/CallCenter/List/Registration';
import StockistListScreen from '../screens/Stockist/List/StockistList.screen';
import DoctorDetailsScreen from '../screens/Doctor/List/DoctorDetails.screen'
import UploadNewPrescriptionScreen from '../screens/Upload/UploadPrescription.screen'
import BUDetailsScreen from '../screens/BU/List/BUDetails.screen';
import NSMDetailsScreen from '../screens/NSM/List/NSMDetails.screen';
import ZMDetailsScreen from '../screens/ZM/List/ZMDetails.screen';
import RMDetailsScreen from '../screens/RM/List/RMDetails.screen';
import AuditPageScreen from '../screens/CallCenter/List/AuditList.screen';
import AdminDashboardScreen from  '../screens/Admin/Dashboard.screen';
import AuditTrial from  '../screens/Admin/AuditTrial.screen';
import OrderManagment from  '../screens/Admin/OrderManagment/OrderManagment.screen';
import TrackOrderAdmin from  '../screens/Admin/OrderManagment/TrackOrder.screen';
import PatientDetailsAdmin from  '../screens/Admin/OrderManagment/PatientDetails.screen';
import QrCodeGenerationScren from  '../screens/Admin/Qr/QrCodeGeneration.screen';
import UserManagmentScreen from  '../screens/Admin/UserManagment/UserManagmentDashboard.screen';
import StockistManagementScreen from  '../screens/Admin/UserManagment/StockistManagementDashboard.screen';
import CallCenterManagementScreen from  '../screens/Admin/UserManagment/CallCenterManagementDashboard.screen';
import CallCenterEditScreen from  '../screens/Admin/UserManagment/CallCenterEdit.screen';
import CallCenterAddScreen from  '../screens/Admin/UserManagment/CallCenterAdd.screen';
const Test = () => <><div>test</div></>
const RouterArr = () => {
  const routes = [

    {
      path: "/customer/",
      exact: true,

      children: [
        { path: "registration", exact: true, guards: [customerLoginGuard], element: <Registration /> },
        { path: "payment", exact: true, guards: [customerLoginGuard], element: <OrderPlaced /> },
        { path: "place-order", exact: true, guards: [customerLoginGuard], element: <PlaceOrder /> },
        { path: "order-information", exact: true, guards: [customerLoginGuard], element: <OrderInformation /> },
        { path: "order-info", exact: true, guards: [customerLoginGuard], element: <OrderInfo /> },
        { path: "login/:prescribedDoctor", guards: [loginGuard], exact: true, element: <Login /> },
        { path: "feedback-info", exact: true, guards: [customerLoginGuard], element: <FeedbackScreen /> },
        { path: "feedback", exact: true, guards: [customerLoginGuard], element: <FeedbackFromOrderScreen /> },
        { path: "orders", exact: true, guards: [customerLoginGuard], element: <OrderHistory /> },
        { path: "order-history", exact: true, guards: [customerLoginGuard], element: <OrderHistoryTracking /> },
        { path: "upload-new-prescription", exact: true, guards: [customerLoginGuard], element: <UploadNewPrescriptionScreen /> },
        { path: "order-info-tracking", exact: true, guards: [customerLoginGuard], element: <OrderInfoTracking /> },
        { path: "feedback-submit", exact: true, guards: [customerLoginGuard], element: <FeedbackSubmitScreen /> },
        { path: "privacy-policy", exact: true, guards: [customerLoginGuard], element: <PrivacyPolicy /> },
        { path: "feedback-list", exact: true, guards: [customerLoginGuard], element: <FeedbackListScreen /> },
        { path: "track-order-info/:unique_id", exact: true,guards: [customerLoginGuard], element: <TrackOrderByOrderId /> }
        
      ]
    },
    {
      path: "/mr/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <MrDetailsScreen /> },
        { path: "login", exact: true, guards: [mrLoginGuard], element: <LoginMR/> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/bu/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <BUDetailsScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/nsm/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <NSMDetailsScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/zm/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <ZMDetailsScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/rm/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <RMDetailsScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/stockist/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <StockistListScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
       
      ]
    },
    ,
    {
      path: "/callcenter/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <CallCenterListScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
        { path: "patient-details", guards: [mrLoginGuard], exact: true, element: <RegistrationScreenCallCenter/> },
        { path: "audit-page", guards: [mrLoginGuard], exact: true, element: <AuditPageScreen/> },
        
       
      ]
    },
    {
      path: "/doctor/",
      exact: true,

      children: [
        
        { path: "list", exact: true, guards: [mrLoginGuard], element: <DoctorDetailsScreen /> },
        { path: "track-info-order", guards: [mrLoginGuard], exact: true, element: <TrackInfoOrder/> },
      ]
    },
    {
      path: "/admin/",
      exact: true,

      children: [
        
        { path: "dashboard", exact: true, guards: [mrLoginGuard], element: <AdminDashboardScreen /> },
        { path: "audit-trial", exact: true, guards: [mrLoginGuard], element: <AuditTrial /> },
        { path: "order-managment", exact: true, guards: [mrLoginGuard], element: <OrderManagment /> },
        { path: "track-order", exact: true, guards: [mrLoginGuard], element: <TrackOrderAdmin /> },
        { path: "patient-details", exact: true, guards: [mrLoginGuard], element: <PatientDetailsAdmin /> },
        { path: "qr-code-generation", exact: true, guards: [mrLoginGuard], element: <QrCodeGenerationScren /> },
        { path: "user-managment", exact: true, guards: [mrLoginGuard], element: <UserManagmentScreen /> },
        { path: "user-managment/stockist", exact: true, guards: [mrLoginGuard], element: <StockistManagementScreen /> },
        { path: "user-managment/callcenter", exact: true, guards: [mrLoginGuard], element: <CallCenterManagementScreen /> },
        { path: "user-managment/callcenter/details", exact: true, guards: [mrLoginGuard], element: <CallCenterEditScreen /> },
        { path: "user-managment/callcenter/add", exact: true, guards: [mrLoginGuard], element: <CallCenterAddScreen /> },
      ]
    },
    {
      path: "/",
      exact: true,
      element: <LoginBaseScreen />,

      children: [

        { path: "/test", exact: true, element: <Test /> }
      ]
    },
    {
      path: "/login",
      exact: true,
      element: <LoginCustomerTrackingScreen />,

      children: [

        // { path: "/test", exact: true, element: <Test /> }
      ]
    },
    {
      path: "*", element: <PageNotFound />
    }
  ];
  // let element = useGuardedRoutes([routes]);

  return useGuardedRoutes(routes);
};




const Router = (props) => {
  return (
    <BrowserRouter>
      <GuardConfigProvider>
        <GuardProvider>
          <RouterArr />
        </GuardProvider>

      </GuardConfigProvider>

      {/* <Routes>
        <Route exact path="/" element={<Login />} />
        <Route element={<PageNotFound />} />
      </Routes>  */}
      {/* <Outlet />*/}
    </BrowserRouter>
  )
}

export default Router