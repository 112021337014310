import {
    PLACE_ORDER, 
    SAVE_ORDERS, 
    SAVE_ALL_ORDERS, 
    SAVE_SELECTED_ORDERS,
    CLEAR_ORDER_STATE,
    SET_PAYMENT_DETAILS
  } from '../types/Order.type'
  class OrderAction {
    placeOrder = (data) => {
      return { type: PLACE_ORDER, payload: data }
    }
    saveOrders = (data) => {
      return { type: SAVE_ORDERS, payload: data }
    }

    saveAllOrders = (data) => {
      return { type: SAVE_ALL_ORDERS, payload: data }
    }

    saveSelectedOrders = (data) => {
      return { type: SAVE_SELECTED_ORDERS, payload: data }
    }

    savePaymentDetails = (data) => {
      return { type: SET_PAYMENT_DETAILS, payload: data }
    }

    clearState = () => {
      return { type: CLEAR_ORDER_STATE, payload: null }
    }
  }
  export default new OrderAction()