import React from "react";
import LogoutIcon from "../../../assets/Svg/LogoutICon";
import CommonAction from "../../../redux/actions/Common.action";
import MrAction from "../../../redux/actions/Mr.action";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import CustomerAction from "../../../redux/actions/Customer.action";
import FeedbackAction from "../../../redux/actions/Feedback.action";
import OrderAction from "../../../redux/actions/Order.action";
export default function CustomerHeader() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mrdetails = useSelector((state) => state.MrReducer.mrdetails);
    const logout = async () => {
        try {
            console.log(mrdetails, "logout");
            await dispatch(CommonAction.logout());
            await dispatch(CustomerAction.clearState());
            await dispatch(FeedbackAction.clearState());
            await dispatch(OrderAction.clearState());
            await dispatch(MrAction.logout());

            // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;

            setTimeout(() => {
                const url = "/";
            navigate(url)
            }, 2000);
            
        } catch (error) {
            // alert(JSON.stringify(error))
        }
    }
    return (
        <>
            <div className="row">
                <div className="w-100 logo-top-box mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-wrap">
                            <a href="javascript:void(0);"><img src={require("../../../assets/images/logo.png")} alt="logo" /></a>
                        </div>
                        <div>
                            <button type="submit" className="btn-logout" onClick={() => { logout() }}>
                                <LogoutIcon />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}