import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
class UserManagmentDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

    }


    componentDidMount() {

    }

    navigateTo = (type) =>{
        if(type=='stockist'){
            this.props.navigate('/admin/user-managment/stockist')

        }

        if(type=='callcenter'){
             this.props.navigate('/admin/user-managment/callcenter')
        }


    }
    render = () => {

        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-5'>
                                        <h5 className='mb-20'>User Management</h5>
                                    </div>
                                    {/* Row end */}
                                    <div className="row g-4">
                                        <div className="col-md-6">
                                            <div className="card card-animate">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <a className='' href='javascript:void(0)' onClick={() => { this.navigateTo('stockist') }}>
                                                        <div>
                                                            <p className="fw-medium text-muted mb-0">Stockist</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                {/* <span className="counter-value" data-target="28.05" />k */}
                                                            </h2>
                                                            <p className="mb-0 text-muted">
                                                                {/* <span className="badge bg-light text-success mb-0">
                                                                    <i className="ri-arrow-up-line align-middle" /> 16.24 %
                                                                </span>
                                                                vs. previous month */}
                                                            </p>
                                                        </div>
                                                        </a>
                                                        {/* <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                                <span className="avatar-title bg-light-red text-primary-red rounded-circle fs-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 512 512"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M432 32a63.973 63.973 0 0 0-34.344 117.963L355.894 296.13A64.372 64.372 0 0 0 352 296a63.659 63.659 0 0 0-38.193 12.678l-77.154-64.295A64 64 0 1 0 131.259 269.7l-45.292 90.588A64.334 64.334 0 0 0 80 360a64.082 64.082 0 1 0 36.243 11.29l42.8-85.589a63.845 63.845 0 0 0 59.982-14.356l74.7 62.252a64 64 0 1 0 92.621-27.56l41.76-146.167c1.289.078 2.585.13 3.894.13a64 64 0 0 0 0-128ZM80 456a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Zm96-200a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Zm176 136a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Zm80-264a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {/* end card body */}
                                            </div>
                                            {/* end card*/}
                                        </div>
                                        {/* end col*/}
                                        <div className="col-md-6">
                                            <div className="card card-animate">
                                                <a className='' href='javascript:void(0)' onClick={() => { this.navigateTo('callcenter') }}>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                       <a className='' href='javascript:void(0)'>
                                                       <div>
                                                            <p className="fw-medium text-muted mb-0">Call Center</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                {/* <span className="counter-value" data-target="97.66" />k */}
                                                            </h2>
                                                            {/* <p className="mb-0 text-muted">
                                                                <span className="badge bg-light text-danger mb-0">
                                                                    <i className="ri-arrow-down-line align-middle" /> 3.96 %
                                                                </span>
                                                                vs. previous month
                                                            </p> */}
                                                        </div>
                                                       </a> 
                                                        {/* <div>
                                                            <div className="avatar-sm flex-shrink-0">
                                                                <span className="avatar-title bg-light-red text-primary-red rounded-circle fs-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <g fill="none">
                                                                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                                                                            <path
                                                                                fill="currentColor"
                                                                                d="M12 2c5.517 0 10 4.445 10 9.943c0 1.362-.169 2.341-.426 3.133c-.611 1.882-1.874 3.465-3.302 4.8c-.982.918-2.439.778-3.314-.092c-1.242-1.235-1.582-3.152-.235-4.49l.92-.915a2.5 2.5 0 0 1 3.472-.05c.277.26.414.57.557.13c.181-.56.328-1.327.328-2.516C20 7.562 16.424 4 12 4c-4.424 0-8 3.562-8 7.943c0 1.19.147 1.955.328 2.516c.143.44.28.13.557-.13a2.5 2.5 0 0 1 3.472.05l.92.915c1.347 1.338 1.007 3.255-.235 4.49c-.875.87-2.332 1.01-3.314.093c-1.438-1.344-2.687-2.908-3.302-4.8C2.17 14.283 2 13.304 2 11.942C2 6.445 6.483 2 12 2Zm5.052 13.798c-.578.574-1.79 1.467-.92 2.333c.192.19.491.548.774.284c.638-.596 1.263-1.27 1.683-1.837c-.33-.31-1.026-1.287-1.537-.78Zm-10.104 0c-.51-.507-1.207.47-1.537.78c.42.567 1.045 1.24 1.683 1.837c.282.264.582-.093.774-.284c.87-.866-.342-1.759-.92-2.333Z"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                </a>
                                                {/* end card body */}
                                            </div>
                                            {/* end card*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(UserManagmentDashboard)))