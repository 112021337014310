import {
  SET_PRODUCTS,
  CLEAR_PRODUCT_STATE
} from '../types/Product.type'

const initState = {
  products: []
}

export default (state = initState, action) => {
  const { type, payload } = action
  console.log(action);
  switch (type) {
    case SET_PRODUCTS:
      return Object.assign({}, state, { products: payload })
    case CLEAR_PRODUCT_STATE:
      return Object.assign({}, initState)
    default:
      return state
  }
}