import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import CustomerFooter from "../../components/layout/CustomerFooter";
import moment from "moment";
import CallCenterHeader from "../../components/layout/CallCenter/CallCenterHeader";
import AdminService from "../../services/Admin.service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CallCenterService from "../../services/CallCenter.service";
import { ExportToExcel } from "../../components/Excel/ExportToExcel";
import AdminHeader from "../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../components/layout/Admin/AdminFooter";
import '../../assets/css/admin/style.css';
import '../../assets/css/admin/responsive.css';
class AuditTrial extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            info: null,
            auditList: [],
            auditListNew: [],
            exportData: null,
            fileName: "Audit_Trial",
            searchMonth: "",
            selectedRole: "",
            newarray: [],
            selectedUser: "",
            fromdate: "",
            todate: "",
            addressField: [
                { name: 'country' },
                { name: 'addressLine1' },
                { name: 'addressLine2' },
                { name: 'town' },
                { name: 'state' },
                { name: 'pin' }
            ]
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            admin: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    componentDidMount() {
        const admindetails = this.props.admin;
        const _id = admindetails._id;
        let details = {
            _id: _id
        };

        if (admindetails) {


            AdminService.getAdminInfo(details).then(data => {
                console.log(data.data, "callcenter info");

                if (data.data.success && data.data.data.data !== '') {
                    console.log(data.data.data.data[0], "okkkkk");
                    this.setState({ info: data.data.data.data[0] });
                    // }

                }

            }).catch(err => {

                console.log(err, "err")
            })

            this.getAuditList();

            const currentDate = new Date(); // Get the current date
            const currentYear = currentDate.getFullYear(); // Get the current year
            const currentMonth = currentDate.getMonth() + 1; // Get the current month (Note: Months are zero-based, so add 1)
            const finalText = currentYear + "-" + currentMonth;
            this.setState({ searchMonth: finalText });

            // console.log(currentYear+"-"+currentMonth,1000)

        } else {


        }
    }

    getAuditList = (payload = null) => {

        console.log(payload, "852415")

        this.setState({ auditList: [] }, () => {
            CallCenterService.getAuditList({ type: "admin", startdate: payload?.fromdate, enddate: payload?.todate, searchRole: payload?.searchRole, searchUser: payload?.searchUser }).then(data => {
                console.log(data.data, "audit details 2");

                if (data.data.success && data.data.data.response !== '') {
                    console.log(data.data.data.response, "okkkkk12");

                    // this.getDoctors(data.data.data.callcenterdetails);
                    // this.setState({auditListNew:[]});
                    
                    const formatedData = this.formatData(data.data.data.response);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData, auditList: data.data.data.response })
                    // const sortedData = [...this.state.auditListNew].sort((a, b) => new Date(b._id) - new Date(a._id));
                    // console.log(sortedData,66666);
                    // this.setState({ auditList: sortedData });
                }

            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    formatData(auditdata) {
        let formatedArr = [];
        const { addressField } = this.state;
        for (let i = 0; i < auditdata.length; i++) {

            for (let j = 0; j < auditdata[i].data.length; j++) {

                let changeField = "";

                if (auditdata[i].data[j].action_type == "Modify") {

                    console.log(auditdata[i].data[j].change_field, "123456")
                    const foundItem = addressField.find((item) => item.name === auditdata[i].data[j].change_field);

                    console.log(foundItem, "foundItem");

                    if (foundItem) {
                        changeField = auditdata[i].data[j].customer.address[auditdata[i].data[j].change_field]
                        // console.log(`${itemToCheck} exists in the array.`);
                    } else {
                        changeField = auditdata[i].data[j].customer[auditdata[i].data[j].change_field]
                        // console.log(`${itemToCheck} does not exist in the array.`);
                    }
                }

                formatedArr.push({
                    "User Name": auditdata[i].data[j].user ? auditdata[i].data[j].user.name : "",
                    "User Number": auditdata[i].data[j].user ? auditdata[i].data[j].user.mobileno : "",
                    "Customer Name": auditdata[i].data[j].customer ? auditdata[i].data[j].customer.name : "",
                    "Customer ID": auditdata[i].data[j].customer ? auditdata[i].data[j].customer.customer_code : "",
                    "Doctor name": auditdata[i].data[j].doctor ? auditdata[i].data[j].doctor.name : "",
                    "Field Changed": auditdata[i].data[j].change_field,
                    "Old Record": auditdata[i].data[j].customer_old_value,
                    "New Record": changeField,
                    "Action Type": auditdata[i].data[j].action_type,
                    "User Type": auditdata[i].data[j].user_type,
                    "Change Date & Time": moment(auditdata[i].data[j].createdAt).format("DD-MM-YYYY h:m a")
                })
            }
        }
        return formatedArr;
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    searchByMonth = (event) => {
        const value = event.target.value;
        this.setState({ searchMonth: value })
        this.getAuditList({ searchMonth: value });


        console.log(value)
    }

    changeData = (event) => {
        // alert(event.target.value);
        const value = event.target.value;
        this.setState({ selectedRole: value });

        let payload = {
            user_type: value
        };
        if (value != '') {
            AdminService.getuserListByRole(payload).then(data => {
                console.log(data.data, "1000");

                if (data.data.success && data.data.data.data !== '') {

                    this.formatDropdownData(data.data.data.data);
                    // console.log(data.data.data.data[0], "1000");
                    // this.setState({ info: data.data.data.data[0] });
                    // }

                }

            }).catch(err => {

                console.log(err, "err")
            })
        }
    }

    formatDropdownData = (alldata) => {

        const { selectedRole } = this.state;
        let formatedNewArr = [];
        let isExist = [];
        if (selectedRole != 'DOCTOR') {
            for (let i = 0; i < alldata.length; i++) {
                formatedNewArr.push({
                    'id': alldata[i].user._id,
                    'name': alldata[i].user.name

                });

            }

        } else {
            console.log(alldata,'doctor5555');
            for (let i = 0; i < alldata.length; i++) {
                formatedNewArr.push({
                    'id': alldata[i].doctor_id._id,
                    'name': alldata[i].doctor_id.name

                });

            }
        }
        this.setState({ newarray: formatedNewArr });
        return formatedNewArr;


    }

    searchAudit = (event) => {
        // alert();
        const { selectedRole, selectedUser, fromdate, todate, } = this.state;
        console.log(fromdate,"fromdate123");
        
        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = fromdate?selectedMomentFrom.format('YYYY-MM-DD'):null;

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = todate?selectedMomentTo.format('YYYY-MM-DD'):null;

        this.getAuditList({ searchRole: selectedRole, searchUser: selectedUser, fromdate: selectedOnlyDateFrom, todate: selectedOnlyDateTo });
    }
    changeUser = (event) => {
        // alert(event.target.value);
        this.setState({ selectedUser: event.target.value });
    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }
    clearFilter = (event) => {
        this.setState({ selectedRole: "", selectedUser: "", fromdate: "", todate: "", auditListNew: [] });
        this.getAuditList();
    }

    render = () => {
        const { info, exportData, fileName, auditList, addressField, searchMonth, selectedRole, newarray, selectedUser, fromdate, todate } = this.state
        console.log('auditList', auditList);
        return (

            <>

                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className="landing-form-dtls">
                                        <div className="row mb-25 align-items-center">
                                            <div className="col-md-4">
                                                <h5 className="mb-20">Audit Trial Report of All User Activity</h5>
                                            </div>

                                            <div className="col-md-4">
                                                {/* <div className="row align-items-center"> */}
                                                {/* <div className="col-md-10">
                                                        <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-md-center">
                                                            <div className="col-md-3 pe-0 text-md-end">
                                                                <label className="me-3 form-label mb-md-0">Select Month: </label>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <input className="form-control" type="month" onChange={(event) => this.searchByMonth(event)} value={searchMonth} />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                {/* <div className="col-md-8"> */}
                                                <div className="floatright mt-4 mt-md-0">
                                                    <ExportToExcel apiData={exportData} fileName={fileName} />
                                                </div>
                                                {/* </div> */}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 mb-2">
                                                <label htmlFor className="form-label lvl-clr-hd">From Date</label>
                                                {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                <DatePicker
                                                    selected={fromdate}
                                                    onChange={(date) => this.changeValFromDate(date)}
                                                    dateFormat="dd-MM-yyyy" // Set the desired display format
                                                    className="form-control name-pt-ft"
                                                    placeholderText="DD-MM-YYYY"
                                                />
                                            </div>
                                            <div className="col-md-2 mb-2">
                                                <label htmlFor className="form-label lvl-clr-hd">To Date</label>
                                                {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                                <DatePicker
                                                    selected={todate}
                                                    onChange={(date) => this.changeValToDate(date)}
                                                    dateFormat="dd-MM-yyyy" // Set the desired display format
                                                    className="form-control name-pt-ft"
                                                    placeholderText="DD-MM-YYYY"
                                                    minDate={this.state.fromdate}
                                                />
                                            </div>
                                            <div className="col-md-2">

                                                <label htmlFor className="form-label lvl-clr-hd">Select Role:</label>

                                                <select className="form-control" name="selectedRole" onChange={(event) => this.changeData(event)} value={selectedRole}>
                                                    <option value="">Select Role</option>
                                                    <option value="MR">MR</option>
                                                    <option value="STOCKIST">Stockist</option>
                                                    <option value="BU">BU</option>
                                                    <option value="NSM">NSM</option>
                                                    <option value="ZM">ZM</option>
                                                    <option value="RM">RM</option>
                                                    <option value="DOCTOR">DOCTOR</option>
                                                    <option value="CALLCENTER">CALL CENTER</option>

                                                </select>


                                            </div>
                                            {selectedRole != "" ? <div className="col-md-2">

                                                <label htmlFor className="form-label lvl-clr-hd">Select User:</label>

                                                <select className="form-control" name="selectedUser" onChange={(event) => this.changeUser(event)} value={selectedUser}>
                                                    <option value="">Please Select</option>
                                                    {newarray.map((item, index) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))}

                                                </select>


                                            </div> : ""}

                                            {/* <div className="col-md-4">
                                                        <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-md-center">
                                                            <div className="col-md-3 pe-0 text-md-end">
                                                                <label className="me-3 form-label mb-md-0">Select Month: </label>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <input className="form-control" type="month" onChange={(event) => this.searchByMonth(event)} value={searchMonth} />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-1 mt-2 me-2">
                                                {/* <div className="d-flex flex-column flex-md-row"> */}

                                                {/* <div className="col-md-5"> */}
                                                <button className="btn btn-primary searchbtn" name="search" value="Search" onClick={(event) => this.searchAudit(event)}>Search</button>
                                                {/* </div> */}
                                                {/* </div> */}

                                            </div>
                                            <div className="col-md-2 mt-2 clearfiltermargin">
                                                {/* <div className="d-flex flex-column flex-md-row"> */}

                                                {/* <div className="col-md-5"> */}
                                                <button className="btn btn-success clearfilterdata" name="search" value="Search" onClick={(event) => this.clearFilter(event)}>Clear Filter</button>
                                                {/* </div> */}
                                                {/* </div> */}

                                            </div>
                                        </div>

                                        <div className="">

                                            {auditList.map((item, index) => (

                                                <div className="row" key={item._id}>
                                                    <div className="col-md-12 auditdateheading mt-4">
                                                        <h6 className="fw-bold">{item._id ? moment(item._id).format("DD-MM-YYYY") : ""}</h6>
                                                    </div>
                                                    <hr className="horizanlatrule" />
                                                    {item.data.map((item1, index1) => (



                                                        <div className="row auditrowstyle" key={item1._id}>
                                                            <div className="col-md-2">
                                                                {item1.createdAt ? moment(item1.createdAt).format("h:m a") : ""}
                                                            </div>

                                                            {item1.action_type == "Modify" ? (
                                                                <div className="col-md-10">

                                                                    {addressField.find((item) => item.name === item1.change_field) ?
                                                                        <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "Call Center" : (item1.user_type ? item1.user_type.charAt(0).toUpperCase() + item1.user_type.slice(1) : "")}) changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer ? item1.customer.customer_code : ""}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer.address[item1.change_field] : ""} </p> :

                                                                        <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "Call Center" : (item1.user_type ? item1.user_type.charAt(0).toUpperCase() + item1.user_type.slice(1) : "")}) changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer ? item1.customer.customer_code : ""}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer[item1.change_field] : ""}</p>

                                                                    }
                                                                </div>

                                                            ) : (


                                                                item1.action_type == "Login" ? (

                                                                    <div className="col-md-10">


                                                                        {item1.user_type != "doctor" ? <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "Call Center" : (item1.user_type ? item1.user_type.charAt(0).toUpperCase() : "") + (item1.user_type ? item1.user_type ? item1.user_type.slice(1) : "" : "")}) Logged in </p> : <p className="messagestyle">{item1.doctor ? item1.doctor.name : ""} (Doctor) Logged in </p>}


                                                                    </div>) : (

                                                                    <div className="col-md-10">


                                                                        <p className="messagestyle">{item1.user ? item1.user.name : ""} ({item1.user_type == "callcenter" ? "Call Center" : (item1.user_type ? item1.user_type.charAt(0).toUpperCase() + item1.user_type.slice(1) : "")}) has {item1.action_type} the order of {item1.customer ? item1.customer.name : ""} ({item1.customer ? item1.customer.customer_code : ""}) with order number {item1.order ? item1.order.order_id : ""} for {item1.reject_reason}. </p>


                                                                    </div>

                                                                )

                                                            )}


                                                        </div>

                                                    ))}


                                                </div>


                                            ))}


                                            {auditList.length > 0 ? "" : <p className="align-items-center norecord"><b>No Record Found</b></p>}



                                        </div>

                                    </div>
                                </div>
                                {/* Row end*/}
                            </div>
                        </div>
                        <AdminFooter />
                    </div>

                </div>









            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const mapStateToProps = ({ AdminReducer, CommonReducer }) => {
    const { admin } = AdminReducer
    const { summaryDetails } = CommonReducer
    return {
        admin,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(AuditTrial)))