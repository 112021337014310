import React , {useEffect} from "react";
import ModalCheckIcon from "../../assets/Svg/ModalCheckIcon";
import { useNavigate } from 'react-router-dom';
import $ from "jquery"
export default function OrderPlacedModal({currentOrder,navigateUrl}) {
    const navigate = useNavigate();
    useEffect(()=>{
      
    })
    
    return (
        <>
            <div
                className="modal modal-xs fade"
                id="orderPlaced"
                aria-hidden="true"
                aria-labelledby="paymentSuccessModalLabel"
                tabIndex={-1}
                data-keyboard="false"
                backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered modal-payment-success">
                    <div className="modal-content border-0">
                        <div className="modal-check-icon">
                            <ModalCheckIcon />
                        </div>
                        <div className="modal-header border-0 pt-5 mx-auto">
                            <h1
                                className="modal-title fs-5 text-center fw-bold"
                                id="paymentSuccessModalLabel"
                            >
                                Your Order Has been placed!
                            </h1>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            {/* <p className="text-center">
                                You’ll get confirmation Message soon. Your item are now ready for
                                shipment.
                            </p> */}
                            <h1
                                className="modal-title fs-5 text-center fw-bold mx-auto"
                                id="paymentSuccessModalLabel"
                            >
                                Order ID-{currentOrder.order_id ? currentOrder.order_id : currentOrder._id}
                            </h1>
                        </div>
                        <div className="modal-footer border-0">
                            <button className="btn bg-primary w-100" onClick={()=>{
                                navigate(navigateUrl)
                                $('.modal-backdrop').hide();
                            }}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}