import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  DOCTOR_LOGIN_API, 
  DOCTOR_DETAIL,
  DOCTOR_INFO
} from './Slugs'
import DoctorAction from '../redux/actions/Doctor.action';
// import config from '../../config/knorish.config';
// import AuthAction from '../redux/actions/Auth.action'
// import Validation from '../models/index.model'
// import { STRINGS } from '../utils/strings';
// import TenantInfoAction from '../redux/actions/TenantInfo.action';

class DoctorService extends Base {
  constructor(props) {
    super(props)
  }

  doctorLogin(data) {
    return this.post(DOCTOR_LOGIN_API, data);
  }
  // verifyOtp(data) {
  //   return this.post(DOCTOR_VERIFY_OTP, data);
  // }

  getdoctorDetails(data) {
    return this.post(DOCTOR_DETAIL,data);
  }
  getdoctorInfo(data) {
    return this.post(DOCTOR_INFO,data);
  }

}

export default new DoctorService()