import React from "react";
export default function ShippingAddress({customer,usertype,currentOrder,bankrefno}){
    console.log("><><><><><>",bankrefno)
    return (
        usertype != "mr" && usertype != "bu" && usertype != "nsm" && usertype != "zm" && usertype != "rm"?
        <div className="shipng-address">
            <h5>Shipping Address</h5>
            <h6>{customer && customer.name}</h6>
            <p>
                Street: {customer && customer.address && customer.address.addressLine1}
                
                <br />
                City: { customer && customer.address && customer.address.town}
                <br />
                PIN: { customer && customer.address && customer.address.pin}
                <br />
                State/province/area: {customer && customer.address && customer.address.state}
                <br />
                Phone number {customer && customer.mobileno}
            </p>

             { currentOrder=="cod"?<p> Payment Type : COD</p>: currentOrder=="onlinePayment"?<p> Payment Type : Online Payment</p>:""}
             {bankrefno!=""?<p>Payment Ref No. : {bankrefno}</p>:""}
        </div>:""
    )
}