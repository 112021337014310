let config = Object.assign({})
const production = require('./env.config').production

Object.defineProperties(config, {
  apiTimeout: { 
    value: production ? 10 * 1000 : 20 * 1000, // 10 or 20 sec
    writable: false
  },
  serviceUrl: {
    value: production ? 'https://api.emrok.co.in' : 'http://192.168.0.135:9001', // client dev server
    // value: production ? 'https://api.emrok.co.in' : 'http://192.168.0.216:9000',
    // value: production ? 'https://api.emrok.co.in' : 'http://192.168.29.105:9000',
    //  value: production ? 'https://api.emrok.co.in' : 'http://192.168.0.32:9000',
    // value: production ? 'https://api.emrok.co.in' : 'http://192.168.0.16:9001',
    //  value: production ? 'http://devapi.emrok.co.in' : 'http://192.168.0.32:9000',
    // value: production ? 'https://api.emrok.co.in' : 'http://192.168.0.16:9001',
    //  value: production ? 'http://emrokapi.maxmobility.in' : 'http://192.168.0.32:9000',
    // value: production ? 'https://api.emrok.co.in' : 'http://192.168.29.105:9000',
    //  value: production ? 'https://api.emrok.co.in' : 'http://192.168.0.138:9000',
    writable: false
  },
  refreshTimeout: {
    value: production ? 60 : 10, //in minutes
    writable: false
  },
  xApiToken: { 
    value:"1234567809",
    writable: false
  },
  knowMoreLink: {
    value: "https://emrok.co.in/",
    writable: false
  },
  feedbackCommentCharLimit: {
    value: 300,
    writable: false
  },
  bdUrl: {
    value: "https://www.bluedart.com/?",
    writable: false
  },
  ccavenueMerchantID:{
    value: "2742902",
    writable: false
  },
  ccavenueAccessCode:{
    // value: "AVOL05KH15CN68LONC",//test
    value: "AVFQ97KH72AS42QFSA",
    writable: false
  },
  ccavenueWorkingKey:{
    // value: "7E7BA057A319A75DB981703E93F63266",//test
    value: "E40F12F945F9A136E00569FC051AC596",
    writable: false
  },
  ccavenueRedirectURL:{
    // value: "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
    value: "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
    writable: false
  }
})
Object.seal(config)

export default config