import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import moment from "moment";
import CallCenterHeader from "../../../components/layout/CallCenter/CallCenterHeader";
import CallCenterService from "../../../services/CallCenter.service";
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import { ExportToExcel } from '../../../components/Excel/ExportToExcel'
class CallCenterList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callcenterdetails: null,
            scallcenterid: "",
            callcenterList: [],
            callcenterinfo: "",
            callcenterdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryCols: ["Total Rx generated", "Total Rx Cancel", "Total Rx Fulfilled", "Total Rx Under process"],
            summaryDetails: null,
            setorderStatus: "",
            auditList: [],
            exportData: null,
            fileName: "Audit_Trial",
            addressField: [
                { name: 'country' },
                { name: 'addressLine1' },
                { name: 'addressLine2' },
                { name: 'town' },
                { name: 'state' },
                { name: 'pin' }
            ]

        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            callcenterdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getAuditList = () => {
        this.setState({ auditList: [] }, () => {
            CallCenterService.getAuditList({type:"callcenter"}).then(data => {
                console.log(data.data, "audit details 2");

                if (data.data.success && data.data.data.response !== '') {
                    console.log(data.data.data.response, "okkkkk12");

                    // this.getDoctors(data.data.data.callcenterdetails);
                    // let onlyModifyItem = data.data.data.response.find((item) => item.action_type == "Modify");

                    // console.log(onlyModifyItem,"onlyModifyItem")

                    const formatedData = this.formatData(data.data.data.response);

                    console.log(formatedData, "formatedData")

                    this.setState({ exportData: formatedData });

                    this.setState({ auditList: data.data.data.response });
                }

            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    componentDidMount() {

        this.getAuditList();



    }

    formatData(auditdata) {

        console.log(auditdata,"allauditdata")
        console.log( auditdata.length,"allauditdatalength")
    

        let formatedArr = [];
        const {addressField} = this.state;
       

        for (let i = 0; i < auditdata.length; i++) {
            console.log(auditdata[i].data.length,"allauditdata123");

            for (let j = 0; j < auditdata[i].data.length; j++) {
                console.log(auditdata[i].data[j],"alldata123456")

                // if(auditdata[i].data[j].action_type=="Modify"){

                    console.log(auditdata[i].data[j],"alldata123456")

                    let changeField = "";
                    const foundItem = addressField.find((item) => item.name === auditdata[i].data[j].change_field);
    
                    if (foundItem) {
    
                        changeField = auditdata[i].data[j].customer.address[auditdata[i].data[j].change_field]
    
                        // console.log(`${itemToCheck} exists in the array.`);
                    } else {
                        changeField = auditdata[i].data[j].customer[auditdata[i].data[j].change_field]
                        // console.log(`${itemToCheck} does not exist in the array.`);
                    }
    
    
                    formatedArr.push({
    
                        "User Name": auditdata[i].data[j].user?.name,
                        "User Number": auditdata[i].data[j].user?.mobileno,
                        "Customer Name": auditdata[i].data[j]?.customer?.name,
                        "Customer ID": auditdata[i].data[j].customer?.customer_code,
                        "Field Changed": auditdata[i].data[j].change_field,
                        "Old Record": auditdata[i].data[j].customer_old_value,
                        "New Record": changeField,
                        "Change Date & Time": moment(auditdata[i].data[j].createdAt).format("DD-MM-YYYY h:m a")
                    })
                // }

                


            }


        }

        return formatedArr;


    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    handleClickHome = () => {

        this.props.navigate('/callcenter/list')
    }

    render = () => {
        const { auditList, exportData, fileName , addressField} = this.state;
        console.log(auditList,"auditList00");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <CallCenterHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Call Center</h2> */}
                                            {/* <h5>Audit Trial</h5> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}

                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-20">Audit Trial</h5>
                                </div>
                                <div className='col-md-6'>
                                    <div className="btn-home flotright">
                                        <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                            Back
                                        </a>

                                    </div>

                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>
                            </div>

                            <div className="">

                                {auditList.map((item, index) => (
                                    

                                    <div className="row" key={item._id}>
                                        <div className="col-md-12 auditdateheading">
                                            <h6>{item._id ? moment(item._id).format("DD-MM-YYYY") : ""}</h6>
                                        </div>
                                        <hr className="horizanlatrule" />
                                        {item.data.map((item1, index1) => (

                                            

                                            <div className="row auditrowstyle" key={item1._id}>
                                                <div className="col-md-2">
                                                    {item1.createdAt ? moment(item1.createdAt).format("h:m a") : ""}
                                                </div>
                                                <div className="col-md-10">

                                                    {addressField.find((item) => item.name === item1.change_field)?
                                                    <p className="messagestyle">{item1.user ? item1.user.name : ""} changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer.customer_code}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer.address[item1.change_field] : ""} </p>:
                                                    
                                                    <p className="messagestyle">{item1.user ? item1.user.name : ""} changed {item1.change_field} of Customer: {item1.customer ? item1.customer.name : ""} ({item1.customer.customer_code}). Old Record: {item1.customer_old_value} , New Record: {item1.customer ? item1.customer[item1.change_field] : ""}</p>

                                                    }
                                                </div>

                                            </div>

                                        ))}


                                    </div>


                                ))}



                            </div>
                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        // setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        // setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, CallCenterReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { callcenterdetails } = CallCenterReducer
    const { summaryDetails } = CommonReducer

    console.log(customer, 123);
    console.log(callcenterdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        callcenterdetails,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(CallCenterList)))