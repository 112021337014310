import React from 'react';

class TimerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timerSeconds: 60,
      showResendButton: false,
      resendOtpFun: undefined,
      otpSent: true,
      countdown: 30,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      resendOtpFun: props.resendOtp || undefined,
    }
  }
  
  componentDidMount() {
    // if (!this.state.otpSent) {
      // this.setState({ otpSent: true });
      this.startCountdown(true);
      // this.state.resendOtpFun && this.state.resendOtpFun()
    // }
  }

  handleResendClick = (e) => {
    e.preventDefault()
    if (!this.state.otpSent) {
      this.setState({ otpSent: true });
      this.startCountdown();
      this.state.resendOtpFun && this.state.resendOtpFun()
    }
  };

  startCountdown = (init = false) => {
    this.timer = setInterval(() => {
      if (this.state.countdown === 1) {
        clearInterval(this.timer);
        this.setState({
          otpSent: false,
          countdown: 30,
        });
      } else {
        this.setState((prevState) => ({
          countdown: prevState.countdown - 1,
        }));
      }
      // if(init && this.state.countdown === 30) {
      //   this.setState({otpSent: true})
      // }
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  // Function to handle timer logic
  // startTimer = () => {
  //   this.timer = setInterval(() => {
  //     this.setState(prevState => ({
  //       timerSeconds: prevState.timerSeconds - 1
  //     }), () => {
  //       if (this.state.timerSeconds === 0) {
  //         clearInterval(this.timer);
  //         this.setState({ showResendButton: true });
  //       }
  //     });
  //   }, 1000); // 1000 milliseconds = 1 second
  // };

  // Start the timer when the component mounts
  // componentDidMount() {
  //   this.startTimer();
  // }

  // // Function to handle the resend button click
  // handleResendClick = () => {

  //   this.setState({
  //     timerSeconds: 60,
  //     showResendButton: false
  //   }, () => {

  //     this.startTimer();
  //     // if (!this.state.resendOtpFun) {
  //     //   this.state.resendOtpFun()
  //     // }
  //   });
  // }

  // // Function to convert seconds to "00:00" format
  // formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   const formattedMinutes = String(minutes).padStart(2, '0');
  //   const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  //   return `${formattedMinutes}:${formattedSeconds}`;
  // }

  render() {
    


    return (

      <div>
        {this.state.otpSent ? (
          <p>OTP Sent! Resend in {this.state.countdown} seconds</p>
        ) : (
          <button onClick={this.handleResendClick} className='resendMobOtp'>Resend OTP</button>
        )}
      </div>
      // <div>
      //   {timerSeconds > 0 ? (
      //     <p>Timer counting... ({this.formatTime(timerSeconds)})</p>
      //   ) : (
      //     showResendButton ? (
      //       <button onClick={this.handleResendClick} className='resendMobOtp'>Resend</button>
      //     ) : null
      //   )}
      // </div>
    );
  }
}

export default TimerComponent;