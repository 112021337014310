import Base from './Base.service'
import { store } from '../redux/index.store'
import {
    GENERATE_OTP,
    MATCH_OTP,
    GET_CUSTOMER_OUT,
    SEND_WHATSAPP_MESSAGE,
    GENERATE_SMS_SERVICE,
    GET_CUSTOMER_DETAILS_OUT,
    GET_ORDER_DETAILS_OUT, 
    INITIATE_PAYMENT,
    GET_ENC_REQUEST,
    USER_LOGIN_API,
    GET_ORDER_DETAILS_BY_ID,
    CANCEL_ORDER,
    UPDATE_ORDER_PAYMENT_TYPE,
    GET_TRANSACTION_DETAILS,
    CUSTOMER_LOGIN_TRACKING_API,
    GET_SUMMARY,
    GET_DISCOUNT_VALUE,
    GET_COUPON_VALUE,
    GET_STATUS_TRACK
} from './Slugs'
import CommonAction from '../redux/actions/Common.action';


class OtherService extends Base {
  constructor(props) {
    super(props)
  }

  generateOtp(data){
    return this.post(GENERATE_OTP,data);
  }
  
  verifyOtp(data){
    return this.post(MATCH_OTP,data);
  }
  getCustomerOut(){
    return this.get(GET_CUSTOMER_OUT);
  }
  sendDeliveryMessege(data){
    return this.post(SEND_WHATSAPP_MESSAGE,data);
  }

  generateMsg(data) {
    return this.post(GENERATE_SMS_SERVICE,data);
  }

  getCustomerDetailsOut(data){
    return this.post(GET_CUSTOMER_DETAILS_OUT,data);
  }
  getOrderDetailsOut(data){
    return this.post(GET_ORDER_DETAILS_OUT,data);
  }
  generatePayment(data){
    return this.post(INITIATE_PAYMENT,data);
  }
  getencRequest(data){
    return this.post(GET_ENC_REQUEST,data);
  }
  userLogin(data){
    return this.post(USER_LOGIN_API,data);
  }
  customerTrackingLogin(data){
    return this.post(CUSTOMER_LOGIN_TRACKING_API,data);
  }

  getOrderDetails(data){
    return this.post(GET_ORDER_DETAILS_BY_ID,data);
  }
  cancelOrder(data){
    return this.post(CANCEL_ORDER,data);
  }

  updatePaymentType(data){
    return this.post(UPDATE_ORDER_PAYMENT_TYPE,data)
  }
  getTransactionDetails(data){

    return this.post(GET_TRANSACTION_DETAILS,data)
  }

  getDiscountAmount(){
    return this.get(GET_DISCOUNT_VALUE);
  }

  getCouponList(){
    return this.get(GET_COUPON_VALUE);
  }
  getStatusTrack(data){
    return this.post(GET_STATUS_TRACK,data);
  }

  getSummary(payload) {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_SUMMARY, payload);
        if(response.data.success) {
          if(response.data.data && response.data.data.length) {

            console.log(response.data.data[0],"getSummery12333")
            await store.dispatch(CommonAction.setSummaryDetails(response.data.data[0]))
          }else{
            await store.dispatch(CommonAction.setSummaryDetails())
          }
          
        }
        console.log(response.data.success, response.data.data, "response11");
        return resolve({ success: true })
      } catch (error) {
        console.log(error, "response error");
        return resolve({ success: false, message: error.message || "Something went wrong." })
      }
    })
  }  
}

export default  new OtherService()