import {
    ORDER_LIST,
    SELECTED_ORDER

  } from '../types/OrderList.store'
  class OrderListAction {
    getOrderList = (data) => {
      return { type: ORDER_LIST, payload: data }
    }
    setSelectedOrder = (data) => {
      return { type: SELECTED_ORDER, payload: data }
    }
    
  }
  export default new OrderListAction()