import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';

class FeedbackRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRating: 0,
      // ratings: [],
      changeRating: undefined,
      rating: 0
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      rating: props.rating || 0,
      changeRating: props.changeRating || undefined
    }
  }



  handleRating = (rating) => {
    if(this.state.changeRating) {
      this.state.changeRating(rating)
    }
    // const newRatings = [...this.state.ratings, rating];

    // this.props.handleClick(newRatings);
    // this.setState({ selectedRating: rating, ratings: newRatings });
  };

  render() {
    const { rating, ratings } = this.state;

    return (
      <div>
        <StarRatings
          rating={rating}
          starRatedColor="orange"
          changeRating={this.handleRating}
          numberOfStars={5} // Number of stars in the rating component
          starDimension={20}
          starSpacing="1px"
        />
        {/* <ul>
          {ratings.map((rating, index) => (
            <li key={index}>{rating}</li>
          ))}
        </ul> */}
      </div>
    );
  }
}

export default FeedbackRating;

