import {
    SET_FEEDBACK,
    LOGOUT,
    CLEAR_FEEDBACK_STATE
    } from '../types/Feedback.type'
    class FeedbackAction{
      setMr = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_FEEDBACK, payload: data }
      }
      
      logout = ()=>{
        return { type: LOGOUT, payload: null }
      }

      clearState = () => {
        return { type: CLEAR_FEEDBACK_STATE, payload: null }
      }
     
      
    }
    export default new FeedbackAction()