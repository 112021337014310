import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import TrackShipment from '../../../components/Order/TrackShipment';
import ShippingAddress from '../../../components/Order/OrderShippingAddress';
import OrderService from '../../../services/Order.service';
import config from '../../../config/emrok.config';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import OtherService from '../../../services/OtherService';
import CommonAction from '../../../redux/actions/Common.action';
import CustomerAction from '../../../redux/actions/Customer.action';
import FeedbackAction from '../../../redux/actions/Feedback.action';
import OrderAction from '../../../redux/actions/Order.action';
import MrAction from '../../../redux/actions/Mr.action';
class TrackOrderByOrderId extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            quantity1: 0,
            quantity2: 0,
            selectOrder: [],
            bankrefno: "",
            orderDetails: null,
            rtochangeDate: "",
            refundchangeDate: "",

        }

    }

    checkQuantity() {
        const selectOrder = this.props.OrderReducer.selectedOrders[0];
        console.log(selectOrder, "seelct order");
        let quantity = 0;
        selectOrder.products.forEach((order, i) => {
            if (i === 0) {
                this.setState({ quantity1: order.quantity })
            }
            else {
                this.setState({ quantity2: order.quantity })
            }
            quantity = quantity + order.quantity;
        })
        this.setState({ quantity })
    }
    componentDidMount() {
        const yourParam = this.props.param ? this.props.param.unique_id ? this.props.param.unique_id : null : null;
        const customer = this.props.CustomerReducer.customer
        if (customer) {
            const customerid = customer._id || null;

            if (yourParam && customerid) {

                const splitData = yourParam.split('-');
                if (splitData.length == 2) {
                    const customerid = splitData[0];
                    const order_id = splitData[1];
                    if (customerid != customerid) {


                        OtherService.logout()
                        // const url = "/customer/login/" + customerid;
                        this.props.navigate("/")

                    } else {


                       


                        // getorderdetails
                        OtherService.getOrderDetails({ _id: order_id }).then(data => {
                            console.log(data, "alladata")
                            if (data.data.success) {
                                if (data.data.data.order.length > 0) {
                                    console.log(data.data.data.order[0], "alladata123")
                                    this.setState({ orderDetails: data.data.data.order[0] })
                                    this.props.saveSelectedOrders(data.data.data.order[0]);


                                    OtherService.getTransactionDetails({ order_id: data.data.data.order[0].order_id }).then(data => {
                                        // console.log(data.data.data[0], "alladata transaction")
                                        if (data.data.success) {

                                            // console.log(data.data.data[0].bank_ref_no,"data12345");


                                            if (data.data.data.length > 0) {
                                                console.log(data.data.data[0], "alladata123")
                                                this.setState({ bankrefno: data.data.data[0].bank_ref_no })
                                            }
                                        }
                                        else {

                                        }
                                    })

                                    OtherService.getStatusTrack({ order_id: data.data.data.order[0].order_id }).then(response => {

                                        if (response.data.success) {
                                            console.log(response, "statustrack")
                        
                                            const changeDate = response.data.data[0].change_date;
                                            if (changeDate != "" && response.data.data[0].status == "RTO") {
                                                this.setState({ rtochangeDate: changeDate })
                                            }
                                            if (changeDate != "" && response.data.data[0].status == "Refund Completed") {
                                                this.setState({ refundchangeDate: changeDate })
                                            }
                                            // console.log(changeDate,"changeDate")
                                        }
                                    })

                                }
                            }
                            else {
                                alert("ok");
                            }
                        })


                    }
                }
                else {
                    alert("ok");

                }
            }
            else {
                //login
                alert("ok");
            }
        } else {
            //login
            alert("ok");
            console.log("customer else");
        }
    }

    goBackPrevious = () => {
        const usertype = this.props.CommonReducer.userType;

        if (usertype == "callcenter") {
            this.props.navigate('/callcenter/list');
        }
        if (usertype == "mr") {
            this.props.navigate('/mr/list');
        }
        if (usertype == "doctor") {
            this.props.navigate('/doctor/list');
        }
        if (usertype == "manufactur") {
            this.props.navigate('/manufactur/list');
        }
        if (usertype == "stockist") {
            this.props.navigate('/stockist/list');
        }

        // this.props.history.goBack();
        // this.props.navigate(this.props.backurl);
    };


    orderItemListJsx = () => {
        const selectOrder = this.props.OrderReducer.selectedOrders;
        console.log(selectOrder, "selectOrder123");

        const { quantity, quantity1, quantity2 } = this.state;
        return (
            <>
                {
                    selectOrder.products.map((product, i) => {
                        return (
                            <>
                                <h6>
                                    <div className="tablts-dtls">
                                        <div>{product.name}</div>
                                        <div>₹{product.price}  {i === 0 ? " x " + quantity1 : " x " + quantity2}</div>
                                    </div>
                                </h6>
                            </>
                        )
                    })
                }

            </>
        )
    }

    orderCancel = () => {
        console.log(this.props.OrderReducer, "this.props.OrderReducer");
        if (this.props.OrderReducer.selectedOrders && this.props.OrderReducer.selectedOrders._id) {
            let payload = {
                order_id: this.props.OrderReducer.selectedOrders._id,
                status: "cancelled"
            }
            OrderService.updateOrderStatus(payload).then(data => {
                if (data.success) {
                    this.props.hoc.customAlertWithClick("Your order is cancelled ", true)
                    // this.props.navigate('/customer/registration');
                }
                else {
                    alert("Something went wrong.")
                }
            })

        }
    }

    handleDownload = () => {

        const selectOrder = this.props.OrderReducer.selectedOrders;

        const downloadUrl = config.serviceUrl + "/images/invoice/" + selectOrder.order_id + ".pdf"; // Replace with the actual URL or path of the file


        window.open(downloadUrl, '_blank');

    };

    render = () => {
        const selectOrder = this.props.OrderReducer.selectedOrders;
        console.log(selectOrder, "selectOrder")
        const { quantity, quantity1, quantity2, bankrefno, refundchangeDate ,rtochangeDate} = this.state;
        const customer = this.props.OrderReducer.selectedOrders.customer;
        console.log("?>>>>>>", customer)
        const usertype = this.props.CommonReducer.userType;
        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <CustomerHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="ordr-info-part mt-4">
                                <div className='row mt-20'>
                                    <div className='col-md-6'>
                                        <h2>Order Information</h2>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.goBackPrevious}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>

                                </div>
                                <p>
                                    <span className="ordr-fst-id">Order ID</span>
                                    <span className="ordr-num"> {selectOrder.order_id ? selectOrder.order_id : selectOrder._id}</span>
                                </p>
                            </div>

                            <div className="col-md-6 price-details-pt">
                                <div className="price-details-dtls mt-4">
                                    <h5>Price Details</h5>
                                    <h4>Total Quantity ({quantity})</h4>
                                    {
                                        this.orderItemListJsx()
                                    }
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Grand Total</div>
                                            <div>₹{selectOrder.totalBeforeDiscount ? (parseFloat(+selectOrder.totalBeforeDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>
                                    <div className="tablts-dtls">
                                        <div>Discount({selectOrder.discountApplied}%)</div>
                                        <div>₹{selectOrder.discountAmount ? (parseFloat(+selectOrder.discountAmount)).toFixed(2) : 0}</div>
                                    </div>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Coupon Applied ({selectOrder.couponCode})</div>
                                            <div>₹{selectOrder.couponDiscount ? (parseFloat(+selectOrder.couponDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Tax</div>
                                            <div>₹00</div>
                                        </div>
                                    </h6> */}
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Amount Paid</div>
                                            {selectOrder.payment_type == "cod" ? <div>₹00</div> : <div>₹{selectOrder.total ? (parseFloat(+selectOrder.total)).toFixed(2) : 0}</div>}
                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Due Amount</div>
                                            {selectOrder.payment_type == "cod" ? <div>₹{selectOrder.total ? (parseFloat(+selectOrder.total)).toFixed(2) : 0}</div> : <div>₹00</div>}
                                        </div>
                                    </h6>
                                    <p className='notetext'>* Price Inclusive of Taxes</p>
                                    <hr />
                                </div>
                                <ShippingAddress customer={customer} usertype={usertype} currentOrder={selectOrder.payment_type} bankrefno={bankrefno} />
                            </div>
                            <TrackShipment orderCancel={this.orderCancel} currentOrder={selectOrder} orderplacedDate={selectOrder ? selectOrder.createdAt : ""} orderPickUpdate={selectOrder.awbtrack ? selectOrder.awbtrack.ShipmentPickupDate : ""} awbno={selectOrder.awbtrack ? selectOrder.awbtrack.AWBNo : ""} orderConfirmdDate={selectOrder.confirm_date ? selectOrder.confirm_date : ""} rtochangeDate={rtochangeDate} refundchangeDate={refundchangeDate}/>

                            <div className="dwn-btn-trk mt-4 dwn-btn-trk-wrap">
                                <div className="dwn-align-prft">
                                    {/* <button
                                        type="button"
                                        className="feedbk-wrt"
                                        onClick={() => {
                                            console.log(this.props, 898);
                                            this.props.navigate("/customer/feedback-info")
                                        }}
                                    >
                                        Write Feedback
                                    </button> */}
                                    {/* <a
                                        type="button"
                                        className="feedbk-wrt downloadinvoice"
                                        onClick={this.handleDownload}
                                        // href={`${config.serviceUrl}/images/invoice/${selectedOrders.order_id}.pdf`}
                                        // download="download"
                                        target='_blank'
                                    >
                                        Download Invoice
                                    </a> */}
                                </div>
                                <div>
                                    <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a>
                                </div>
                            </div>
                            <CustomerFooter />
                        </div>
                    </div>
                </section>
            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(TrackOrderByOrderId)))