import axios from 'axios'
import config from '../config/emrok.config';
import { store } from "../redux/index.store";
import CommonAction from '../redux/actions/Common.action';
import CustomerAction from '../redux/actions/Customer.action';
import FeedbackAction from '../redux/actions/Feedback.action';
import OrderAction from '../redux/actions/Order.action';
import MrAction from '../redux/actions/Mr.action';
class Base {
  constructor() {
    this.http = null
    this.#init()
  }

  get(url) {
    return this.http.get(url);
  }

  post(url, data) {
    return this.http.post(url, data);
  }

  put(url, data) {
    return this.http.put(url, data);
  }

  #init = async () => {

    let header = {
      "x-api-token": config.xApiToken,
      "app-name": "emrok"
    }

    this.http = await axios.create({
      baseURL: config.serviceUrl,
      headers: header
    })

    // Request interceptors for API calls
    this.http.interceptors.request.use(
      data => {
        let hideLoader = data ? data.data ? data.data.hasOwnProperty("hideLoader") ? data.data.hideLoader : false : false : false
        console.log(hideLoader, "hideLoader datatata");
        const { CommonReducer } = store.getState();
        const token = CommonReducer.token;
        data.headers['Authorization'] = `Bearer ${token}`;
        if (data) {
            if (!hideLoader) {
              this.showLoader()
            }
        }
        else {
          this.showLoader()
        }
        return data;
      },
      error => {
        this.hideLoader()
        return Promise.reject(error);
      }
    );

    //response interceptor
    this.http.interceptors.response.use(response => {
      this.hideLoader()
      return response;
    }, (error) => {
      this.hideLoader()
      return Promise.reject(error)
    });
  }

  showLoader() {
    store.dispatch(store.dispatch(CommonAction.getLoaderAction(true)))
  }

  hideLoader() {
    store.dispatch(store.dispatch(CommonAction.getLoaderAction(false)))
  }

  logout(){

    store.dispatch(CommonAction.logout());
    store.dispatch(CustomerAction.clearState());
    store.dispatch(FeedbackAction.clearState());
    store.dispatch(OrderAction.clearState());
    store.dispatch(MrAction.logout());
  }

}



export default Base