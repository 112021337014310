import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import DoctorHeader from "../../../components/layout/Doctor/DoctorHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import DoctorService from "../../../services/Doctor.service";
import DoctorAction from "../../../redux/actions/Doctor.action";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import CommonAction from "../../../redux/actions/Common.action";
import Summary from "../../../components/Summary/Summary.component";
class DoctorDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            doctordetails: null,
            doctorid: "",
            doctordetailsList: [],
            doctorinfo: "",
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            isSearch: false,
            summaryCols: ["No. of Patients", "No. of Patients Received the Medicine"],
            summaryDetails: null,
            setorderStatus: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            doctordetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async (doctor_id = null) => {
        if (doctor_id) {
            // let payload = {
            //     type: "DOCTOR",
            //     id: doctor_id
            // }

            const { fromdate, todate, selectedState, selectedTown, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');
    
            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');
    
    
            let payload = {
                type: "DOCTOR",
                id: doctor_id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: "",
                town: "",
                orderStatus: setorderStatus
            };

            console.log(payload,"payload12333");
    
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        const doctordetails = this.props.doctordetails;
        let payload = {};
        if (doctordetails) {
            // let payload = await {
            //     doctor_id: doctordetails._id
            // }

            const { fromdate, todate, selectedState, selectedTown, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');
    
            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');
    
    
            
            if (index === 0) {
                payload = {
                    doctor_id: doctordetails._id,
                    fromDate: "",
                    toDate: "",
                    stateName: "",
                    town: "",
                    orderStatus: ""
                };
            }
            else if (index === 1) {
                payload = {
                    doctor_id: doctordetails._id,
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: "",
                    town: "",
                    orderStatus: setorderStatus
                };
                payload["orderStatus"] = await "complete"
                this.setState({ setorderStatus: "complete" })
            }
            this.getDoctorDetails(payload)
        }

    }

    getDoctorDetails = (details) => {
        this.setState({ doctordetailsList: [] }, () => {
            DoctorService.getdoctorInfo(details).then(data => {
                console.log(data.data, "doctor info");

                if (data.data.success && data.data.data.mrinfo !== '') {
                    console.log(data.data.data, "okkkkk");
                    // if(!this.state.isSearch){
                    this.setState({ doctordetailsList: data.data.data });
                    // }
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    componentDidMount() {

        const doctordetails = this.props.doctordetails;

        console.log(doctordetails, "doctordetails list");
        // console.log(details, "doctor details",);
        if (doctordetails) {
            const doctor_id = doctordetails._id;
            let details = {
                doctor_id: doctor_id
            };
            this.getSummary(doctor_id)

            this.getDoctorDetails(details)


            DoctorService.getdoctorDetails(details).then(data => {
                console.log(data.data, "doctor details");

                if (data.data.success && data.data.data !== '') {
                    console.log(data.data, "okkkkk doctor details");


                    this.setState({ doctorinfo: data.data.data[0] });

                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            // setTimeout(() => {
            //     this.populateStates("IN")
            //     // this.populateStates(countryObj.isoCode)	
            // }, 500);
            // } else {


            // }



        }
    }

    changeValFromDate = async (date) => {
        
        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date,"fromdate");
        this.setState({ fromdate: date });
    }

    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            const materials = data[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');

            resp.push({
                _id: data[i]._id,
                stockist_name: data[i].stockist.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_state: data[i].customer.address.state,
                customer_town: data[i].customer.address.town,
                customer_mobile: data[i].customer.mobileno,
                order_date: data[i].createdAt ? data[i].createdAt : "",
                grn_date: data[i].GRN_DATE ? data[i].GRN_DATE : "",
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                order_id: data[i].order_id ? data[i].order_id : "",
                total: data[i].total ? data[i].total : "",
                product_code: materialString,

            })
        }
        return resp


    }

    searchFilter = (event) => {

        this.setState({ doctordetailsList: [] });
        const doctordetails = this.props.doctordetails;

        console.log(doctordetails, "doctordetails list");
        const doctor_id = doctordetails._id;
        const { fromdate, todate, selectedState, selectedTown, setorderStatus } = this.state;

        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
        const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
        const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


        let details = {
            doctor_id: doctor_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: "",
            town: "",
            orderStatus: setorderStatus
        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            DoctorService.getdoctorInfo(details).then(data => {
                console.log(data.data, "doctor info");

                if (data.data.success && data.data.data.mrinfo !== '') {
                    console.log(data.data.data, "okkkkk");

                    this.setState({ doctordetailsList: data.data.data });
                    this.setState({ isSearch: true })
                    this.getSummary(doctor_id)
                }else{
                    this.getSummary(doctor_id)
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        }




        console.log("search filter");
    }



    // setOrderHistoryData = (data) => {
    //     let resp = []
    //     for (let i = 0; i < data.length; i++) {
    //         const materials = data[i].products.map(product => product.material);
    //         // Create a comma-separated string from the "material" values
    //         const materialString = materials.join(', ');

    //         resp.push({
    //             _id: data[i]._id,
    //             stockist_name: data[i].stockist.name,
    //             doctor_name: data[i].doctor.name,
    //             customer_name: data[i].customer.name,
    //             customer_state: data[i].customer.address.state,
    //             customer_town: data[i].customer.address.town,
    //             customer_mobile: data[i].customer.mobileno,
    //             order_date: data[i].createdAt ? moment(data[i].createdAt).format('DD-MM-YYYY') : "",
    //             grn_date: data[i].GRN_DATE ? moment(data[i].GRN_DATE).format('DD-MM-YYYY') : "",
    //             order_status: data[i].orderStatus ? data[i].orderStatus : "",
    //             order_id: data[i].order_id ? data[i].order_id : "",
    //             total: data[i].total ? data[i].total : "",
    //             product_code: materialString,

    //         })
    //     }
    //     return resp


    // }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/doctor/track-info-order');
                    this.props.setUserType('doctor');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }

    render = () => {

        let _data = this.setOrderHistoryData(this.state.doctordetailsList)
        const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, summaryCols, summaryDetails } = this.state;
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <DoctorHeader />
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Call Center</h2> */}
                                            <h5>Welcome {this.state.doctorinfo.name} to Emrok Application (Dr.)</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-form-dtls mt-20">
                            {/* <h5 className="mt-10 mb-20">Welcome {this.state.doctorinfo.name} to Emrok Application (Dr.)</h5> */}


                            <div className="row mt-30 mb-20 ">
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order From Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order To Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={this.state.fromdate}
                                    />
                                </div>

                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                        <button type="button" className="landing-clear-btn" onClick={(event) => window.location.reload()}>
                                        Clear Filter</button>
                                </div>
                                {/* Summary table start */}
                                <Summary
                                    columns={summaryCols}
                                    data={[{
                                        customerCount: summaryDetails ? summaryDetails.customerCount || 0 : 0,
                                        completeOrderCount: summaryDetails ? summaryDetails.completeOrderCount || 0 : 0,
                                    }]}
                                    clickFn={this.clickFn}
                                />
                                {/* <div className="summary-table mt-32 mb-4">
                                    <h5 className="mb-20">Summary</h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered-custom">
                                            <thead>
                                                <tr>
                                                    <th scope="col">No of Patients</th>
                                                    <th scope="col">No of Patients received the medicine</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Lorem</td>
                                                    <td>Lorem</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}
                                {/* Summary table end */}
                                <h5 className="mb-20">Details</h5>
                            </div>
                            {
                                _data && _data.length ?
                                    <CustomDatatable
                                        pagination={true}
                                        columns={[
                                            {
                                                name: 'Order No.',
                                                // selector: row => row.stockist_name,

                                            },
                                            {
                                                name: 'Patient Name',
                                                // selector: row => row.doctor_name,

                                            },
                                            {
                                                name: 'Product Name',
                                                // selector: row => row.createdAt,

                                            },
                                            {
                                                name: 'Order Date',
                                                // selector: row => row.createdAt,

                                            },
                                            {
                                                name: 'Status',
                                                // selector: row => row.createdAt,

                                            }
                                        ]}
                                        data={this.setOrderHistoryData(this.state.doctordetailsList)}
                                        pageName="doctorlanding"
                                        handleTrackOrder={this.handleTrackOrder}
                                    />
                                    : <p className="text-center">No Records Found</p>
                            }

                            {/* <div className="table-responsive">
                                <table
                                    id="example"
                                    className="table table-striped tbl-chng-pt table-responsive"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th width="">MR Name</th>
                                            <th width="">Patient Detail</th>
                                            <th width="">Blood Collection</th>
                                            <th width="">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        {this.state.doctordetailsList.map((item, index) => (
                                            <tr className="landing-frm-load">
                                                <td>{ item.mr.name }</td>
                                                <td>
                                                    <p className="mt-2">{ item.customer.name }</p>
                                                    <p className="">Mob-{ item.customer.mobileno }</p>
                                                </td>
                                                <td>{item.customer.collection_date?format(new Date(item.customer.collection_date), 'dd-MM-yyyy'):""}</td>
                                                
                                               
                                                <td>
                                                    <button type="button" className="landing-pending-btn">
                                                        Pending
                                                    </button>
                                                </td>
                                            </tr>

                                         ))} 

                                    </tbody>
                                </table>
                            </div> */}
                        </div>

                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, DoctorReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { doctordetails } = DoctorReducer
    const { summaryDetails } = CommonReducer

    console.log(customer, 123);

    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        doctordetails,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DoctorDetails)))