import { store } from "../redux/index.store";
export const loginGuard = (to,from,next,route)=>{
    const {CommonReducer} = store.getState();
    const token = CommonReducer.token;
    if(token){
        next("/customer/registration");
    }
    else{
        next();
    }
}

export const customerLoginGuard = (to,from,next,route)=>{
    const {CommonReducer} = store.getState();
    const token = CommonReducer.token;
    if(!token){
        
        next("/")
    }
    else{
      
        next();
    }
}

export const mrLoginGuard = (to,from,next,route)=>{
    const {CommonReducer} = store.getState();
    const token = CommonReducer.token;
    // alert(JSON.stringify(token))
    if(!token){
        
        next("/")
    }
    else{
      
        next();
    }
}