import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../../components/Reusable/WithRouter'
import CustomerHeader from '../../components/layout/CustomerHeader'
import config from '../../config/emrok.config'
import OrderPlacedModal from '../../components/Order/OrderPlacedModal'
import ThankYouModal from './ThankYouModal'
import CustomerFooter from '../../components/layout/CustomerFooter'
import Common from '../../hoc/Common.hoc'
import FeedbackService from '../../services/FeedbackService'
import ProductService from '../../services/ProductService'
import FeedbackRating from '../../components/FeedbackRating/FeedbackRating.component'
import ProductAction from '../../redux/actions/Product.action'


class FeedbackFromOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      message_1: "",
      message_2: "",
      customer: null,
      selectedOrders: null,
      rating_1: 0,
      rating_2: 0,
      rating: 0,
      message: '',
      rating1: 0,
      message1: '',
      category: '',
      details: null // Default rating
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      products: props.products || [],
      customer: props.customer || null,
      selectedOrders: props.selectedOrders || null,
    }
  }

  componentDidMount = () => {
    this.getProducts()

    const customer = this.props.CustomerReducer;
    const selectedOrders = this.props.OrderReducer;
    const feedbackDetails = this.props.details;

    console.log(customer, "customer from feedback screen");
    console.log(selectedOrders, "order from feedback screen");
  }

  getProducts = () => {

    const {customer} = this.state;

    const stockist_id = customer!=""?customer.prescribedDoctor.stockist_id:"";
    console.log(customer,"customerData")
    let stockistPayload = {
        stockist_id:stockist_id
    }

    ProductService.getStockistCode(stockistPayload).then(result => {
      console.log(result,"result")

        if(result.data.success){
            console.log(result.data.data[0],"stockist details")

            let plant_id = result.data.data[0].plant_id
            ProductService.getProductsByPlantCode({plant_id}).then(res => {
                console.log(res,"response1233")
                if (res && res.data && res.data.success) {
                    if (res.data.data) {
                        const products = res.data.data;
                        this.props.setProducts(products);
                      
    
                        // this.setState({ products: products, productQuantity: newProductQuantity })
                    }
    
                }
    
            }).catch(e => {
                console.log("e>>>>>>>>>>>>", e)
            })
        }
        // console.log(res,"stockist details")

    });


    // ProductService.getAllProductsByDoctor().then(data => {
    //   console.log(data, "getProducts")
    // })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { message_1, message_2, customer, category, products, rating_1, rating_2 , selectedOrders } = this.state
    // const orders = this.props.OrderReducer;
    //  console.log(selectedOrders,"order");
    // return
    // alert(category.length);
    if(category.length==0){
      this.props.hoc.customAlert("Please choose category", false, "Warning");
    }else if(rating_1=='' && rating_2==''){
      this.props.hoc.customAlert("Please provide ratings.", false, "Warning");
    }else if(message_1=='' && message_2==''){
      this.props.hoc.customAlert("Please add comments.", false, "Warning");
    }else{
      let dataToSubmit = {
        customer_id: customer._id || null,
        order_id : selectedOrders._id || null,
        feedbackType: category,
        products: [
          {
            product_id: products[0]._id,
            comment: message_1,
            rating: rating_1
          },
          {
            product_id: products[1]._id,
            comment: message_2,
            rating: rating_2
          }
        ]
      }
  
      // console.log(dataToSubmit, "dataToSubmit")
      // return
      FeedbackService.createFeedback(dataToSubmit).then(data => {
        console.log(data.data, "feedback");
  
        // if (data.data.success && data.data.data.mrinfo !== '') {
        //   console.log(data.data.data.mrinfo[0], "okkkkk");
  
        //   this.setState({ mrinfo: data.data.data.mrinfo[0] });
        // }
  
  
  
        // this.setState({mrdetailsList:data.data.data.mrdetails});
        this.props.hoc.customAlertWithClick("Thank you for your feedback", true, "Thank you");
  
      }).catch(err => {
  
        this.props.hoc.customAlertWithClick("Thank you for your feedback", true, "Thank you");
  
        console.log(err, "err")
      })
    }



  }
  // handelSubmit = () =>{

  //   this.props.hoc.customAlertWithClick("Thank you for your feedback",true,"Thank you");
  // }

  handleRatingChange = (e) => {
    this.setState({ rating: e.target.value });

  };
  handleRatingChange1 = (e) => {
    this.setState({ rating1: e.target.value });

  };

  handleInputChange = (e) => {
    this.setState({ category: e.target.value });
  };


  handleMessageChange = (e, index) => {
    e.preventDefault()

    const messageData = e.target.value;
  
    if(messageData.length<=config.feedbackCommentCharLimit){
      if (index === 1) {
        this.setState({ message_1: e.target.value });
      }
      else {
        this.setState({ message_2: e.target.value });
      }
    }else{
      this.props.hoc.customAlert("You exced charecter limit", false, "Warning");
    }

    
  };

  handleMessageChange1 = (e) => {
    this.setState({ message1: e.target.value });
  };


  render = () => {
    const { rating, message, rating1, message1, category, products, message_1, message_2, rating_1, rating_2 } = this.state;
    console.log(products, "ppppppppppp")
    return (
      <>
        <section className="feedback-form">
          <div className="container">
            <CustomerHeader />

            {/* Row End*/}
            <div className="row">
              <div className="page-title mt-4">
                <h2 className="lg-title text-capitalize mb-20">Feedback Form</h2>
              </div>
            </div>
            {/* Row End*/}
            <div className="row">
              <div className="col-md-12">
                <div className="feedback-select-box">
                  <form>
                    <div className="">
                      <label htmlFor="" className="form-label">
                        Select Category
                      </label>
                      <select
                        className="form-select feedBackSelect"
                        data-placeholder="Select an option"
                        name="selectedOption"
                        value={this.state.selectedOption}
                        onChange={this.handleInputChange}
                      >
                        <option value="" disabled selected hidden>Please Select</option>
                        <option value="complaint">Complaint</option>
                        <option value="order">Order</option>
                        <option value="query">Query</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* Row End*/}
          </div>
        </section>
        {/*feed-back-form-part-end---------*/}
        {/*write-review-part----------------*/}
        <section className="write-review-pt">
          <div className="container">
            <div className="review-part-ft mt-4">
              <h2>Write a Review</h2>
            </div>
            <div className="review-start-pt">
              {
                products && products.length && products.map((product, i) => {
                  return (
                    <div className="row mt-3" key={i}>
                      <div className="col-3 col-md-2 col-lg-1 product-image-col">
                        <div className="product-dtls-image-content">
                          <div className="product-dtls-image-box">
                            <img src={`${config.serviceUrl}/images/product_img/product-${i + 1}.png`} alt="Product-1" />
                          </div>
                        </div>
                      </div>
                      <div className="col-8 col-md-10 col-lg-11 emrok-pro-detls">
                        <h5>{product?.name}</h5>
                        <div id="half-stars-example">
                          <div className="rating-group">
                            <FeedbackRating
                              rating={i === 0 ? rating_1 : rating_2}
                              changeRating={(rating) => {
                                //alert(rating)
                                if(i === 0) {
                                  
                                  this.setState({rating_1: Math.round(rating)})
                                }
                                else {
                                  this.setState({rating_2: Math.round(rating)})
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="textarea-msg-submt mt-4">
                        <textarea placeholder="Type your query..." defaultValue={""} value={i === 0 ? message_1 : message_2}
                          onChange={(e) => this.handleMessageChange(e, i + 1)} />
                      </div>
                    </div>
                  )
                })
              }
              <div className="foot-wse-btn-pt mt-4">
                <div className="foot-info-fdbk">
                  <div className="know-more-text">
                    <a
                      href={config.knowMoreLink}
                      className="kow-more-link text-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Know more
                    </a>
                  </div>
                </div>
                <div className="footer-bottom-btn-group">
                  <button type="button" className="back-to-main me-3 mb-3" onClick={() => this.props.navigate('/customer/registration')}>
                    Back to Main
                  </button>
                  <button
                    type="submit"
                    //  data-bs-target="#orderPlaced"
                    //  data-bs-toggle="modal" 
                    className="new-sbmt-off" onClick={this.handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
              <CustomerFooter />
            </div>
          </div>
        </section>
        {/* <ThankYouModal dataTargetId={"orderPlaced"} navigateUrl={"/customer/order-information"}/> */}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setProducts: (products) => dispatch(ProductAction.setProducts(products))
  }
}

// const mapStateToProps = (state) => {
//   return {
//     OrderReducer: state.OrderReducer,
//     CustomerReducer: state.CustomerReducer
//   }
// }

const mapStateToProps = ({ CustomerReducer, OrderReducer, FeedbackReducer, ProductReducer }) => {
  const { customer } = CustomerReducer
  const { selectedOrders } = OrderReducer
  const { details } = FeedbackReducer
  const { products } = ProductReducer
  console.log(products, 123);
  console.log(customer, 1234);
  console.log(selectedOrders, 12345);
  return {
    // dispatching plain actions
    // OrderListReducer: state.OrderListReducer,
    customer,
    selectedOrders,
    details,
    products
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(FeedbackFromOrder)))