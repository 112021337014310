import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import BackButton from "../Reusable/BackButton";
import DropdownCoupon from '../../components/SearchableDropdown/DropdownCoupon.component';

export default function OrderList({ products, paymentOrder, placeOrder, product_quantities, couponList, selectedValue, onValueChange, applyCoupon, couponDiscount, isShowApplyBtn, removeCoupon }) {

    const handleChange = (newValue) => {
        // const newValue = event.target.value;
        onValueChange(newValue); // Invoke the callback to send the value to the parent
    };

    const handleInputChange = (newValue) => {
        // const newValue = event.target.value;
        onValueChange(newValue); // Invoke the callback to send the value to the parent
    };
    const productNameJsx = () => {
        return products.map((product, i) => {
            return product_quantities[i].quantity ? <h6>
                <span className="ellipsis">
                    {product.name}
                </span>
            </h6> : null
        })
    }

    const paymentQuantity = () => {
        return products.map((product, i) => {
            console.log(product_quantities[i], "ljksdfhjklshjkdfhsjksg")
            return product_quantities[i].quantity ? <>
                <h6>₹{product.price} X {product_quantities[i].quantity}</h6>
            </> : null
        })
    }

    /*******  Current date print by smrajit 25_07_2023  ****************/


    const CurrentDateComponent = () => {
        // Create a new Date object with the desired date (June 25, 2023)
        const date = new Date();

        // Define an array of month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get the parts of the date (day, month, year)
        const day = String(date.getDate()).padStart(2, '0');
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        // Format the date string (e.g., "25-Jun-2023")
        const formattedDate = `${day}-${month}-${year}`;

        return (
            <div>
                {formattedDate}
            </div>
        );
    };

    /*  End */



    return (
        <>
            <div className="title-wrap mt-4">
                <h6 className="sm-title text-uppercase mb-15">Medicine</h6>
                <h1 className="lg-title text-capitalize mb-20">EMROK</h1>
            </div>
            {/* Title end*/}
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="md-title mb-3">Order Details</h5>
                <h6 className="sm-title">
                    <span className="order-date-label">Date:</span>
                    <span className="order-date">{CurrentDateComponent()}</span>
                </h6>
            </div>
            {/* Title end with date */}
            <div className="row">
                <div className="col-8 col-md-8">
                    <div className="order-details-list">
                        <h5>Total Item ({paymentOrder.totalItem})</h5>
                        {
                            productNameJsx()
                        }
                        {/* <div className="mt-4" /> */}
                        <h6>Grand Total</h6>
                        <h6>Discount({paymentOrder.appliedDiscount}%)</h6>
                        <div className="d-flex align-items-center">
                            <div className="col-12">
                                <div className="col-12 d-md-none"><h6>Coupon Code</h6></div>
                                <div className="row">
                                    <div className="col-4 d-none d-md-block"><h6>Coupon Code</h6></div>
                                    <div className="col-10 col-md-5 cstm-col-cpn-drop">
                                        {/* <select className="form-select" aria-label="Default select example" value={selectedValue} onChange={handleChange}>
                                            <option value="">Please Select</option>
                                            {couponList.map((item) => (
                                                <option key={item._id} value={item.code}>{item.code}</option>
                                            ))}
                                        </select> */}

                                        {
                                            couponList.length ?
                                                <DropdownCoupon
                                                    items={couponList}
                                                    onSelect={(item) => {
                                                        if (item) {
                                                            handleChange(item.value)
                                                        }
                                                    }}

                                                    // onInputChange={(item) => {
                                                    //     if (item) {
                                                    //         handleInputChange(item)
                                                    //     }
                                                    // }}
                                                    value={selectedValue}
                                                /> :
                                                <DropdownCoupon
                                                    items={couponList}
                                                    value={selectedValue}
                                                />
                                        }
                                    </div>
                                    <div className="col-2 text-end cstm-col-responsive">
                                        {isShowApplyBtn ? <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-apply" onClick={() => {
                                            applyCoupon();
                                        }}>
                                            Apply
                                        </button> : <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-remove" onClick={() => {
                                            removeCoupon();
                                        }}>
                                            Remove
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6>Amount Paid</h6>
                        <h6>Due Amount</h6>
                    </div>
                    {/* <div className="d-flex justify-content-start">
                        <BackButton className="btn payment-dtls-submit px-4" />

                    </div> */}
                </div>
                <div className="col-4 col-md-4">
                    <div className="text-end order-details-list margin-list">
                        {
                            paymentQuantity()
                        }
                        {/* <div className="mt-4" /> */}
                        <h6>₹{paymentOrder.totalPayment ? (parseFloat(+paymentOrder.totalPayment)).toFixed(2) : 0}</h6>
                        <h6>₹{paymentOrder.discountamount ? (parseFloat(+paymentOrder.discountamount)).toFixed(2) : 0}</h6>
                        <div className="cstm-cpn-value-space"></div>
                        <h6>₹{couponDiscount ? (parseFloat(+couponDiscount)).toFixed(2) : 0}</h6>
                        <h6 className="text-success">₹00</h6>
                        <h6 className="color-ligh-red">₹{paymentOrder.finalPaymentAfterDiscount ? (parseFloat(+paymentOrder.finalPaymentAfterDiscount)).toFixed(2) : 0}</h6>
                    </div>
                    <div className="d-flex justify-content-end">
                        {/* <button
                            type="button"
                            className="btn payment-dtls-confirm px-3 px-md-5"
                            onClick={() => {
                                placeOrder();
                            }}
                        >
                            Place Order
                        </button> */}
                        {/* <button
                            type="button"
                            className="btn payment-dtls-confirm px-3 px-md-5"
                        >
                           <Link to="/customer/orders">Order History</Link> 
                        </button> */}
                    </div>
                </div>
                <div className="d-flex justify-content-between my-4 place-order-btn-group">
                    <BackButton className="btn payment-dtls-submit px-4" />
                    <button
                        type="button"
                        className="btn payment-dtls-confirm px-3 px-md-5"
                        onClick={() => {
                            placeOrder();
                        }}
                    >
                        Place Order
                    </button>
                </div>
            </div>
        </>
    )
}