import {
    SET_CALL_CENTER,
    CLEAR_STATE,
    SET_CALL_CENTER_CUSTOMER
} from '../types/CallCenter.type'

const initState = {
    callcenterdetails: {},
    callcentercustomer: {},
    token: null
}

export default (state = initState, action) => {
    const { type, payload } = action
    console.log(action, 565656);
    switch (type) {
        case SET_CALL_CENTER:
            return Object.assign({}, state, { callcenterdetails: { ...payload } })
        case SET_CALL_CENTER_CUSTOMER:
            return Object.assign({}, state, { callcentercustomer: { ...payload } })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}