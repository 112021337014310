import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  CREATE_FEEDBACK, FEEDBACK_LIST
} from './Slugs'
import FeedbackAction from '../redux/actions/Mr.action';
// import config from '../../config/knorish.config';
// import AuthAction from '../redux/actions/Auth.action'
// import Validation from '../models/index.model'
// import { STRINGS } from '../utils/strings';
// import TenantInfoAction from '../redux/actions/TenantInfo.action';

class FeedbackService extends Base {
  constructor(props) {
    super(props)
  }

  // saveFeedback(data) {
  //   return
  // }

  createFeedback(data) {
    return this.post(CREATE_FEEDBACK, data);
  }

  getFedbackDetails(data) {
    return this.get(FEEDBACK_LIST, data);
  }

}

export default new FeedbackService()