import {
  SET_PRODUCTS,
  CLEAR_PRODUCT_STATE
} from '../types/Product.type'
class ProductAction {
  setProducts = (data) => {
    return { type: SET_PRODUCTS, payload: data }
  }
  clearState = () => {
    return { type: CLEAR_PRODUCT_STATE, payload: null }
  }
}
export default new ProductAction()