

import ProductAction from '../redux/actions/Product.action';
import { store } from '../redux/index.store';
import { STRINGS } from '../utils/strings';
import Base from './Base.service';
import {
  GET_PRODUCT,
  GET_STOCKIST_CODE,
  GET_PRODUCT_BY_PLANT_CODE,
  GET_PRODUCT_BY_DOCTOR
} from './Slugs'


class ProductService extends Base {
  constructor(props) {
    super(props)
  }

  getProducts(data) {
    // console.log(data,"data")
    return this.get(GET_PRODUCT,data);
  }
  getProductsByPlantCode(data) {
    return this.post(GET_PRODUCT_BY_PLANT_CODE,data);
  }
  getStockistCode(data){
    return this.post(GET_STOCKIST_CODE,data)
  }

  getAllProductsByDoctor(data){
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_PRODUCT_BY_DOCTOR);
        console.log(response.data, "getAllProducts");
        if (response.data && response.data.success) {
          await store.dispatch(ProductAction.setProducts(response.data.data))
          return resolve({ success: true })
        }
        else {
          return resolve({ success: false, message: STRINGS.COMMON_ERROR })
        }
      } catch (error) {
        return resolve({ success: false, message: error.message || STRINGS.COMMON_ERROR })
      }
    })
  }

  getAllProducts() {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_PRODUCT);
        console.log(response.data, "getAllProducts");
        if (response.data && response.data.success) {
          await store.dispatch(ProductAction.setProducts(response.data.data))
          return resolve({ success: true })
        }
        else {
          return resolve({ success: false, message: STRINGS.COMMON_ERROR })
        }
      } catch (error) {
        return resolve({ success: false, message: error.message || STRINGS.COMMON_ERROR })
      }
    })
  }
}

export default new ProductService()