import React, { useState } from "react";
import config from "../../../config/emrok.config";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import CallCenterAction from "../../../redux/actions/CallCenter.action";
import CommonAction from "../../../redux/actions/Common.action";
import CustomerAction from "../../../redux/actions/Customer.action";
import FeedbackAction from "../../../redux/actions/Feedback.action";
import OrderAction from "../../../redux/actions/Order.action";
import MrAction from "../../../redux/actions/Mr.action";
import DoctorAction from "../../../redux/actions/Doctor.action";
import StockistAction from "../../../redux/actions/Stockist.action";
import AdminAction from "../../../redux/actions/Admin.action";

export default function AdminSidebar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [activeItem, setActiveItem] = useState("dashboard");
    const activeItem = useSelector((state) => state.AdminReducer.activeItem)
    console.log(activeItem, "8787878");
    const navigateTo = (data) => {

        if (data != '') {
            let url = '';
            if (data == 'ordermanagment') {
                url = "/admin/order-managment";
            } if (data == 'audit') {
                url = "/admin/audit-trial";
            }

            if (data == 'dashboard') {
                url = "/admin/dashboard";
            }

            if (data == 'qr') {
                url = "/admin/qr-code-generation";
            }

            if(data == 'user_managment'){
                url = "/admin/user-managment";
            }


            dispatch(AdminAction.setActiveMenuItem(data))
            // setActiveItem(data);
            // alert();
            // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;

            navigate(url)
        }

    }

    const logout = () => {

        dispatch(CallCenterAction.logout());
        dispatch(CommonAction.logout());
        dispatch(CustomerAction.clearState());
        dispatch(FeedbackAction.clearState());
        dispatch(OrderAction.clearState());
        dispatch(MrAction.logout());
        dispatch(DoctorAction.logout());
        dispatch(StockistAction.logout());
        dispatch(AdminAction.logout());
        // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
        const url = "/";
        navigate(url)
    }
    return (
        <>
            <div className="sidebar toggle-xs-sidebar">
                <div className="sidebar-brand">
                    {/* Light Logo*/}
                    <a href="index.html" className="logo logo-light">
                        <span className="logo-lg">
                            <img src={require("../../../assets/images/logo.png")} alt="Logo Large" height={35} />
                        </span>
                    </a>
                </div>
                {/* Logo end*/}
                <div className="sidebar-menu-wrap">
                    <div className="scrollbar scrollbar--sidebar">
                        <div className="sidebar-menu-content">
                            <ul className="nav flex-column sidebar-nav">
                                <li className="nav-item">
                                    <a className={activeItem === "dashboard" ? "nav-link active" : "nav-link"} href="javascript:void(0);" onClick={() => { navigateTo('dashboard') }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M6 19h3v-6h6v6h3v-9l-6-4.5L6 10v9Zm-2 2V9l8-6l8 6v12h-7v-6h-2v6H4Zm8-8.75Z"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">Dashboard</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={activeItem === "ordermanagment" ? "nav-link active" : "nav-link"} href="javascript:void(0);" onClick={() => { navigateTo('ordermanagment') }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                        >
                                            <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
                                                <path d="M22.775 8A9 9 0 0 1 23 10h-9V1a9 9 0 0 1 8.775 7Zm-2.067 0A6.999 6.999 0 0 0 16 3.292V8h4.708Z" />
                                                <path d="M1 14a9 9 0 0 1 11-8.775V12h6.775A9 9 0 1 1 1 14Zm15.803 0H10V7.196A6.804 6.804 0 1 0 16.803 14Z" />
                                            </g>
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">Order Management</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={activeItem === "qr" ? "nav-link active" : "nav-link"} href="javascript:void(0);" onClick={() => { navigateTo('qr') }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M3 11V3h8v8H3Zm2-2h4V5H5v4ZM3 21v-8h8v8H3Zm2-2h4v-4H5v4Zm8-8V3h8v8h-8Zm2-2h4V5h-4v4Zm4 12v-2h2v2h-2Zm-6-6v-2h2v2h-2Zm2 2v-2h2v2h-2Zm-2 2v-2h2v2h-2Zm2 2v-2h2v2h-2Zm2-2v-2h2v2h-2Zm0-4v-2h2v2h-2Zm2 2v-2h2v2h-2Z"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">QR Code Generation</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={activeItem === "user_managment" ? "nav-link active" : "nav-link"} href="javascript:void(0);" onClick={() => { navigateTo('user_managment') }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 32 32"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M8 5c-3.3 0-6 2.7-6 6c0 2 1 3.8 2.5 4.8C1.8 17.2 0 19.9 0 23h2c0-3.3 2.7-6 6-6s6 2.7 6 6h2c0-3.2 2.6-5.9 5.8-6h.2c2.5 0 4.6-1.5 5.5-3.6c0 0 0-.1.1-.1c.1-.1.1-.3.1-.4c0-.1 0-.1.1-.2c0-.1.1-.3.1-.4c0-.1 0-.2.1-.3c0-.1 0-.2.1-.3v-.6c0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2 1 3.8 2.5 4.8c-1.5.7-2.7 1.9-3.5 3.3c-.8-1.4-2-2.6-3.5-3.3C13 14.8 14 13 14 11c0-3.3-2.7-6-6-6zm0 2c2.2 0 4 1.8 4 4s-1.8 4-4 4s-4-1.8-4-4s1.8-4 4-4zm14 0c2.2 0 4 1.8 4 4s-1.8 4-4 4s-4-1.8-4-4s1.8-4 4-4zm2.1 11v2.1c-.6.1-1.2.4-1.7.7l-1.5-1.5l-1.4 1.4l1.5 1.5c-.4.5-.6 1.1-.7 1.8H18v2h2.1c.1.6.4 1.2.7 1.8l-1.5 1.5l1.4 1.4l1.5-1.5c.5.3 1.1.6 1.7.7V32h2v-2.1c.6-.1 1.2-.4 1.7-.7l1.5 1.5l1.4-1.4l-1.5-1.5c.4-.5.6-1.1.7-1.8H32v-2h-2.1c-.1-.6-.4-1.2-.7-1.8l1.5-1.5l-1.4-1.4l-1.5 1.5c-.5-.3-1.1-.6-1.7-.7V18h-2zm.9 4c1.7 0 3 1.3 3 3s-1.3 3-3 3s-3-1.3-3-3s1.3-3 3-3zm0 2a.872.872 0 0 0-.367.086a1.138 1.138 0 0 0-.32.227a1.138 1.138 0 0 0-.227.32A.872.872 0 0 0 24 25c0 .125.031.25.086.367c.055.117.133.227.227.32c.093.094.203.172.32.227A.872.872 0 0 0 25 26c.5 0 1-.5 1-1s-.5-1-1-1z"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">User Management</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="javascript:void(0);">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9ZM5 5v14h8V5H5Zm2 6h4v2H7v-2Zm0-4h4v2H7V7Z"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">Product Management</span>
                                        </div>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a
                                        className="nav-link sidebarMenuDropDown"
                                        href="javascript:void(0);"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <div className="d-flex align-items-center">
                                                <span className="sidebar-menu-text">
                                                    Payment &amp; Billing
                                                </span>
                                                <span className="submenu-dropdown-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <g fill="none" fillRule="evenodd">
                                                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                                                            <path
                                                                fill="currentColor"
                                                                d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414l-5.657 5.657Z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="dropdown-content dropdown-content-collapse-mode">
                                        <div className="scrollbar-dropdown">
                                            <ul className="nav flex-column dropdown-list">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="javascript:void(0);">
                                                        Payment Gateway Integration
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="javascript:void(0);">
                                                        Transaction History &amp; Analytics
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="javascript:void(0);">
                                                        Manage Billing Information &amp; Payment Methods
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="javascript:void(0);">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M4 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1zm0 8a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1zm10-1h6m-6 4h6m-6 4h6"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">
                                                Templates &amp; Key Changes
                                            </span>
                                        </div>
                                    </a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="javascript:void(0);">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M12 17q-.425 0-.713-.288T11 16q0-.425.288-.713T12 15q.425 0 .713.288T13 16q0 .425-.288.713T12 17Zm-1-4V3h2v10h-2Zm-6 8q-.825 0-1.413-.588T3 19v-3h2v3h14v-3h2v3q0 .825-.588 1.413T19 21H5Z"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">Reports</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={activeItem === "audit" ? "nav-link active" : "nav-link"} href="javascript:void(0);" onClick={() => { navigateTo('audit') }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 2048 2048"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M1033 1280q-14 30-21 62t-13 66H384v-128h649zm-649 384v-128h613q5 33 14 65t23 63H384zm-128 256h1349l127 128H128V0h1115l549 549v568q-29-26-61-47t-67-37V640h-512V128H256v1792zM1280 219v293h293l-293-293zm24 805q-109 41-187 128H384v-128h920zM384 512h640v128H384V512zm1152 256v128H384V768h1152zm512 1216q0 26-19 45t-45 19q-26 0-45-19l-291-290q-39 26-84 39t-92 14q-66 0-124-25t-102-68t-69-102t-25-125q0-66 25-124t68-101t102-69t125-26q66 0 124 25t101 69t69 102t26 124q0 47-13 92t-40 84l290 291q19 19 19 45zm-768-512q0 40 15 75t41 61t61 41t75 15q40 0 75-15t61-41t41-61t15-75q0-40-15-75t-41-61t-61-41t-75-15q-40 0-75 15t-61 41t-41 61t-15 75z"
                                            />
                                        </svg>
                                        <div className="sidebar-nav-menu-box">
                                            <span className="sidebar-menu-text">Audit Trial</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* logout Menu start */}
                    <div className="d-flex btn-logout-wrap">
                        <div className="logout-btn-small d-none">
                            <button className="border-0 bg-transparent" type="submit">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-log-out"
                                >
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                    <polyline points="16 17 21 12 16 7" />
                                    <line x1={21} y1={12} x2={9} y2={12} />
                                </svg>
                            </button>
                        </div>
                        <button className="btn sidebar-btn-logout" type="submit" onClick={() => { logout() }}>
                            Logout
                        </button>
                    </div>
                    {/* logout Menu end */}
                </div>
            </div>


        </>
    )
}