import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  GET_TRANSACTION,
  ORDER_LIST_PATH, UPDATE_ORDER_STATUS
} from './Slugs'
import OrderAction from '../redux/actions/Order.action'
// import config from '../../config/knorish.config';
// import AuthAction from '../redux/actions/Auth.action'
// import Validation from '../models/index.model'
// import { STRINGS } from '../utils/strings';
// import TenantInfoAction from '../redux/actions/TenantInfo.action';

class OrderService extends Base {
  constructor(props) {
    super(props)
  }

  getOrders(customer_id) {
   
        let response = this.post(ORDER_LIST_PATH, { customer_id });
        if (response.data && response.data.success) {
          store.dispatch(OrderAction.saveOrders(response.data.data.order))
        }

        return response;
       
  }

  updateOrderStatus(payload) {
    return new Promise(async resolve => {
      try {
        let response = await this.post(UPDATE_ORDER_STATUS, payload);
        console.log(response, "response");
        return resolve({ success: true })
      } catch (error) {
        return resolve({ success: false, message: error.message || "Something went wrong." })
      }
    })
  }

  getTransaction(payload) {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_TRANSACTION, payload);
        console.log(response, "response");
        return resolve({ success: true, data: response })
      } catch (error) {
        console.log(error, "response error");
        return resolve({ success: false, message: error.message || "Something went wrong." })
      }
    })
  }

}

export default new OrderService